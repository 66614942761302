.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 4rem;
}

h3 {
  font-size: 2rem;
  margin-bottom: 12px;
}

h4 {
  margin-top: 12px;
  text-decoration: underline;
}

p {
  font-size: 1.5rem;
}

.top-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2rem;
}

.bottom-section {
  flex: 1;
  padding-top: 2rem;
}

.peppe-img {
  height: 10em;
  width: 10em;
}

.countdown {
  font-size: 3rem;
  font-weight: 700;
} 